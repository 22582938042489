@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /*@font-face {
    font-family: "Avenir";
    src:
      url('/webFonts/AvenirPro45Book/font.woff2') format('woff2'),
      url('/webFonts/AvenirPro45Book/font.woff') format('woff');
    font-weight: 400;
  }
  @font-face {
    font-family: "Avenir";
    src:
      url('/webFonts/AvenirPro65Medium/font.woff2') format('woff2'),
      url('/webFonts/AvenirPro65Medium/font.woff') format('woff');
    font-weight: 500;
  }
  @font-face {
    font-family: "Avenir";
    src:
      url('/webFonts/AvenirPro65Medium/font.woff2') format('woff2'),
      url('/webFonts/AvenirPro65Medium/font.woff') format('woff');
    font-weight: 600;
  }
  @font-face {
    font-family: "Avenir";
    src:
      url('/webFonts/AvenirPro65Medium/font.woff2') format('woff2'),
      url('/webFonts/AvenirPro65Medium/font.woff') format('woff');
    font-weight: 700;
  }
  @font-face {
    font-family: "Avenir";
    src:
      url('/webFonts/AvenirPro85Heavy/font.woff2') format('woff2'),
      url('/webFonts/AvenirPro85Heavy/font.woff') format('woff');
    font-weight: 800;
  }
  @font-face {
    font-family: "Avenir";
    src:
      url('/webFonts/AvenirPro85Heavy/font.woff2') format('woff2'),
      url('/webFonts/AvenirPro85Heavy/font.woff') format('woff');
    font-weight: 900;
  } */

  body {
    @apply font-body;
  }

  .tc-button {
    @apply py-3 px-4 md:px-6 inline-block font-bold rounded hover:shadow text-white bg-ocean-600 hover:bg-ocean-700 !important;
  }

  .banner {
    @apply relative z-10;
    box-shadow:
            0 4px 4px 0 rgba(0, 0, 0, .025),
            0 1px 4px 0 rgba(0, 0, 0, .003);
  }
  a#CybotCookiebotDialogPoweredbyCybot,
  div#CybotCookiebotDialogPoweredByText {
    display: none;
  }

  #CybotCookiebotDialog {
    background-color: #072e4a !important;
    font-family: 'Avenir', sans-serif !important;
  }

  #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection, #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
    @apply py-3 px-4 md:px-6 inline-block font-bold rounded hover:shadow text-white bg-ocean-600 hover:bg-ocean-700 !important;
    border: none !important;
  }

  #CybotCookiebotDialogBodyEdgeMoreDetails a {
    @apply text-white !important
  }

  #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
  .CybotCookiebotDialogDetailBodyContentCookieContainerTypesSelected {
    background-color: #0c4a6e !important; /* Ocean-800 color */
    border-color: #0c4a6e !important;
  }


  /* Styling for the toggle when it's off */
  #CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieContainerTypes {
    background-color: #e2e8f0 !important; /* Slate-200 color */
    border-color: #e2e8f0 !important;
  }

  #CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieContainerTypes:after {
    background-color: white !important;
  }

  @media (min-width: 768px) {
    .title-with-break br {
      content: "";
      display: block;
      margin-bottom: 1rem; /* Adjust this value to increase/decrease space */
    }
  }

  #survey-intro {
    @apply max-w-full md:w-2/3;
  }

  h2.survey-disclaimer {
    @apply text-2xl md:text-4xl font-medium text-ocean-700 text-left !important;
  }
  #survey-intro h3, h3.survey-disclaimer {
    @apply text-lg md:text-xl font-normal text-blue-600 text-left !important;
  }

  #survey-intro p {
    @apply leading-9 text-gray-600 text-lg text-left !important;
    @apply mb-4;
  }

  #survey-intro a {
    @apply text-forest-500 hover:text-forest-600 !important;
  }
}
